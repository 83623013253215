.App{
  direction: rtl;
  background-color: #faf3dd;
}


.app-content{
  /* margin-left: 15%;
  margin-right: 15%; */
  position: relative;
}


