/* Define CSS variables for colors */
:root {
  --red-color: #ffa69e;
  --green-color: #b8f2e6;
  --blue-color: #aed9e0;
}



@media (max-width: 768px) {
  .app-content {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.2rem solid black;
  padding-bottom: 10px;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom-secondary {
  background-color: #c9c4b4; /* Your custom color */
  border-color: #c9c4b4;
  color: white; /* Text color */
}

.custom-dark {
  background-color: #586280; /* Another custom color */
  color: white; /* Text color */
}

.image-and-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
  width: 30%; /* Adjust the width to your preference */
}

/* Adjust gap for smaller screens */
@media (max-width: 768px) {
  .buttons-container {
    align-items: center;
  }
}

.logo-image-headline {
  width: 70%;
  height: auto;
  transform: scale(1.5);
}

.anat-circle {
  max-width: 65%; /* Make the image responsive */
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .anat-circle {
    margin-bottom: 1rem;
    transform: scale(2.2);
  }
}

/* CSS for buttons */
.circle-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 35px;
  
}

.circle-button-container-one{
  width: 200px;
  height: 200px;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d; /* Add perspective for 3D effect */
}

.circle-button-container-one:hover{
  transform: rotateY(180deg);
}

.circle-button-container-two{
  width: 90px;
  height: 90px;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d; /* Add perspective for 3D effect */
}

.circle-button-container-two:hover{
  transform: rotateY(180deg);
}

.circle-button-container-three{
  width: 140px;
  height: 140px;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d; /* Add perspective for 3D effect */
}

.circle-button-container-three:hover{
  transform: rotateY(180deg);
}

.circle-button-container-four{
  width: 90px;
  height: 90px;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d; /* Add perspective for 3D effect */
}

.circle-button-container-four:hover{
  transform: rotateY(180deg);
}


.circle-button {
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  position:relative;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.5s;
  transform-style: preserve-3d; /* Preserve 3D transformations *//* Hide the back face of the button */
  overflow: hidden;
}

.circle-button button {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  backface-visibility: hidden;
}

.circle-button, .circle-image-one {
  position: absolute;
  height: 100%;
  left: 0;
  backface-visibility: hidden;
}

.circle-image-one{
  transform: rotateY(180deg);
}

.circle-button:hover {
  /* transform: rotateY(180deg); */
}

.circle-button:hover img {
  /* transform: rotateY(180deg); */
}

.circle-buttons-wrapper img{
  max-width: 140px;
  height: 140px;
  position: absolute;
  object-fit: cover;
  transform: rotateY(0deg); /* Initially hidden */
  transition: transform 0.5s;
  backface-visibility: hidden; /* Hide the back face of the image */
  z-index: 0;
  
}

/* CSS for circle images */


/* These sizes and colors are consistent for all screen sizes */
.red {
  width: 200px;
  height: 200px;
  background-color: var(--red-color);
}

.green {
  width: 90px;
  height: 90px;
  background-color: var(--green-color);
}

.blue {
  width: 140px;
  height: 140px;
  background-color: var(--blue-color);
}

.form-map-wrapper{
  display: inline-block;
  flex-direction: column; /* To stack form and map vertically on small screens */
  align-items: center; /* To center the form and map horizontally */

  /* Set a maximum width for the form and map on larger screens */
  width: 100%; /* Adjust this value as needed */
  overflow: hidden; /* To hide any content that overflows the parent */
}

.form-card{
  position: relative;
  width: 70%;
}

@media (max-width: 768px){
  .form-card{
    width: 100%;
  }
}

.text-card{
  
  width:115%
}

.text-card li{
  margin: 5px;
  margin-right: -10px;
}

.map-card{
  width: 100%;
  height: auto;
}


/* Adjust margin for smaller screens */
@media (max-width: 768px) {
  .content-header img {
    margin-top: 0;
  }
}
