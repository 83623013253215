.color-overlay {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  mix-blend-mode: normal;
}

@media (max-width: 768px) {
  .color-overlay {
    position: relative;
    height: auto; /* Remove the fixed height */
    width: 100%;
    mix-blend-mode: normal;
  }

  .color-overlay h3 {
    border-bottom: 2px black solid;
  }

  form {
    background-color: #c9c4b4;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    width: 100%; /* Set width to 100% to make it spread across the page */
    max-width: none; /* Remove any maximum width constraints */
    margin: 0; /* Remove margin to prevent spacing on the sides */
    padding: 0; /* Remove padding to make it flush with the edges */
  }
}

.color-overlay h3 {
  border-bottom: 2px black solid;
}

form {
  background-color: #c9c4b4;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.color-overlay button {
  width: 100%;
}

.submit-button {
  background-color: #5e6472;
}
