.logo {
    width: 80px;
    margin-top: 10px;
  }
  
  
  @media (max-width: 768px) {
    .logo {
      width: 60px; /* Adjust the logo size for smaller screens */
      
    }
    /* ... (other responsive styles) */
  }
  
  /* Override Bootstrap Navbar styles */
  .navbar {
    background-color: transparent;
    border: none;
    position: fixed; /* Add position relative */
    min-height: 100px; /* Add a minimum height */
    border-bottom: 0.2rem solid black;
  }
  
  /* Bootstrap Navbar Toggle Icon styles */
  .navbar-toggler-icon {
    background-color: white;
  }
  
  .navbar-items {
    display: flex;
    align-items: flex-end;
  }

  .navbar-items .nav-link:hover {
    color: #62b6cb;
}
  
  /* Apply position: absolute on larger screens */
  @media (min-width: 769px) {
    .navbar-items {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .navbar-items .nav-link {
    margin: 3px;
    text-align: center;
  }

  
  
  /* Responsive styles for mobile screens */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: center;
      justify-content: flex-end; /* Align items at the bottom */
    }
  
    .navbar-toggler {
      margin-top: 10px;
    }
  
    .navbar-collapse {
      text-align: center;
      width: 100%;
    }
  
    .navbar-nav {
      margin-top: 10px;
    }
  
    /* Custom styles for dropdown */
    .dropdown {
      text-align: center;
      transform-origin: bottom;
      position: relative;
    }
    
  
    .dropdown:before {
      content: '';
      position: relative;
      width: 0;
      height: 2px;
      background-color: black; /* Customize the underline color */
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.3s ease-in-out;
    }
  
    .dropdown.show:before {
      width: 50%; /* Adjust the width of the underline */
    }
  }